import { render, staticRenderFns } from "./pager.vue?vue&type=template&id=3229060a&scoped=true"
import script from "./pager.vue?vue&type=script&lang=js"
export * from "./pager.vue?vue&type=script&lang=js"
import style0 from "./pager.vue?vue&type=style&index=0&id=3229060a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3229060a",
  null
  
)

export default component.exports