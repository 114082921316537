import authApiInstance from '@/axios/interceptor/auth';
import { number } from 'echarts/core';

// 获取用户信息
function UserInfoGet() {
  return authApiInstance.get(`/v2/api/UserInfo`);
}

function VCodePost(params) {
  return authApiInstance.post(`/v2/api/Register`,params);
}

function NewCodeGet(mobile, clientType) {
  return authApiInstance.get(`/v2/api/ChangeMobile?clientType=${clientType}&mobile=${mobile}`);
}

function changePhone(params) {
  return authApiInstance.put(`/v2/api/ChangeMobile`, params);
}

export default {
  UserInfoGet,
  VCodePost,
  NewCodeGet,
  changePhone
}

